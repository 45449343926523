/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-09-12",
    versionChannel: "nightly",
    buildDate: "2023-09-12T00:25:43.153Z",
    commitHash: "53199a51164ce338a259f1dba5a850eb7280cf92",
};
